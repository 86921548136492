var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-5",attrs:{"outlined":""}},[_c('div',{staticClass:"img-hover-zoom"},[_c('v-img',{staticClass:"white--text align-end project-image",attrs:{"height":"200px","src":require('@/assets/images/' + _vm.project.imageUrl)}})],1),_c('v-card-title',{staticClass:"text-capitalize text-h5"},[_vm._v(_vm._s(_vm.project.formattedName)+" ")]),_c('div',{staticClass:"ml-3"},_vm._l((_vm.project.projectIcons),function(icon,index){return _c('span',{key:index,staticClass:"v-icon project-icon mx-1"},[_c('i',{class:icon})])}),0),_c('v-card-subtitle',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$Constants.DESCRIPTION))]),_c('v-card-text',{staticClass:"pb-0 description overflow-hidden",staticStyle:{"height":"90px"}},[_c('p',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.project.description)+" ")])]),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"href":_vm.project.html_url,"target":"_blank","icon":"","color":"primary","text":"","large":""}},on),[_c('v-icon',[_vm._v("mdi-file-code-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$Constants.VIEW_SOURCE))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.project.homepage)?_c('v-btn',_vm._g({attrs:{"href":_vm.project.homepage,"target":"_blank","color":"primary","text":"","icon":"","large":""}},on),[_c('v-icon',[_vm._v("mdi-web")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$Constants.GO_TO_APP))])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.project.show = !_vm.project.show}}},[_vm._v(" "+_vm._s(_vm.$Constants.SHOW_MORE)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1),_c('v-expand-transition',[(_vm.project.show)?_c('v-card',{staticClass:"transition-fast-in-fast-out v-card--reveal d-flex flex-column",staticStyle:{"height":"100%"}},[_c('v-card-title',{staticClass:"text-capitalize pb-0"},[_vm._v(_vm._s(_vm.project.formattedName)+" ")]),_c('div',{staticClass:"card__body flex-grow-1"},[_c('v-card-subtitle',{staticClass:"text--secondary py-1"},[_vm._v(" "+_vm._s(_vm.$Constants.DESCRIPTION))]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',{staticClass:"text--primary mb-1"},[_vm._v(" "+_vm._s(_vm.project.description)+" ")])]),_c('v-card-subtitle',{staticClass:"text--secondary py-1"},[_vm._v(" "+_vm._s(_vm.$Constants.PROJECT_TAGS)+" ")]),_c('div',{staticClass:"ml-3 overflow-auto"},_vm._l((_vm.project.topics),function(tag,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"color":"secondary"}},[_vm._v(_vm._s(tag)+" ")])}),1)],1),_c('v-card-actions',{staticClass:"pt-0 my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"href":_vm.project.html_url,"target":"_blank","icon":"","color":"primary","text":"","large":""}},on),[_c('v-icon',[_vm._v("mdi-file-code-outline")])],1)]}}],null,false,1209781892)},[_c('span',[_vm._v(_vm._s(_vm.$Constants.VIEW_SOURCE))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.project.homepage)?_c('v-btn',_vm._g({attrs:{"href":_vm.project.homepage,"target":"_blank","color":"primary","text":"","icon":"","large":""}},on),[_c('v-icon',[_vm._v("mdi-web")])],1):_vm._e()]}}],null,false,142031538)},[_c('span',[_vm._v(_vm._s(_vm.$Constants.GO_TO_APP))])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.project.show = !_vm.project.show}}},[_vm._v(" "+_vm._s(_vm.$Constants.SHOW_LESS)+" "),_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }